export const PhotoService = {
    getData() {
        return [
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0236.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0236.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0237.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0237.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0238.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0238.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0239.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0239.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0240.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0240.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0241.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0241.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0242.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0242.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0244.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0244.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0245.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0245.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0246.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0246.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0247.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0247.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0248.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0248.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0249.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0249.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0250.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0250.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0251.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0251.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0252.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0252.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0253.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0253.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0254.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0254.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0255.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0255.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0256.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0256.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0260.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0260.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0261.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0261.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0262.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0262.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0263.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0263.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0264.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0264.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0265.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0265.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0266.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0266.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0267.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0267.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0269.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0269.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0271.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0271.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0272.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0272.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0274.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0274.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0275.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0275.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0276.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0276.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0277.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0277.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0278.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0278.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0279.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0279.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0280.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0280.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0283.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0283.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0284.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0284.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0285.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0285.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0286.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0286.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0287.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0287.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0288.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0288.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0289.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0289.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0290.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0290.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0291.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0291.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0292.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0292.JPG',
                alt: '',
                title: ''
            },
            {
                itemImageSrc: '/images/zjazd-03-07-2022/PDRM0293.JPG',
                thumbnailImageSrc: '/images/zjazd-03-07-2022/PDRM0293.JPG',
                alt: '',
                title: ''
            },

        ];
    },

    getImages() {
        return Promise.resolve(this.getData());
    }
};

