<template>
  <div class="lg:w-1/2 w-full m-8">
    <p class="text-center">
      Nie ma lekko.<br><br>Zanim zostaniesz wpuszczony musimy sprawdzić czy jesteś na autoryzowanej liście, ergo
      należysz do rodziny
    </p>
    <div class="mt-16 grid grid-cols-2 gap-4">
      <p class="col-span-2 text-center text-primary text-lg mb-2">Imię i nazwisko</p>
      <div class="lg:col-span-1 col-span-2 lg:mb-0 mb-4">
        <FloatLabel>
          <InputText class="w-full" v-model="firstName" type="text" size="large" id="firstName"
            :invalid="firstNameInvalid" placeholder="podaj swoje imię" />
          <label for="firstName">Imię</label>
        </FloatLabel>
      </div>
      <div class="lg:col-span-1 col-span-2 lg:mb-0 mb-4">
        <FloatLabel>
          <InputText class="w-full" v-model="lastName" type="text" size="large" id="lastName" :invalid="lastNameInvalid"
            placeholder="podaj swoje nazwisko, lub nazwisko panieńskie" />
          <label for="lastName">Nazwisko</label>
        </FloatLabel>
      </div>
    </div>
    <div class="mt-16 grid grid-cols-3 gap-4">
      <p class="col-span-3 text-center text-primary text-lg mb-2">Data urodzenia</p>
      <div class="md:col-span-1 col-span-3 lg:mb-0 mb-4">
        <FloatLabel>
          <InputText class="w-full" v-model="dobDay" type="text" size="large" id="dobDay" :invalid="dayInvalid" />
          <label for="dobDay">Dzień</label>
        </FloatLabel>
      </div>
      <div class="md:col-span-1 col-span-3 lg:mb-0 mb-4">
        <FloatLabel>
          <Select v-model="dobMonth" inputId="dobMonth" :options="months" optionLabel="name" class="w-full" size="large"
          style="height:48px" :invalid="monthInvalid" />
          <label for="dobMonth">Miesiąc</label>
        </FloatLabel>
      </div>
      <div class="md:col-span-1 col-span-3 lg:mb-0 mb-4">
        <FloatLabel>
          <InputText class="w-full" v-model="dobYear" type="text" size="large" id="dobYear" :invalid="yearInvalid" />
          <label for="dobYear">Rok</label>
        </FloatLabel>
      </div>
    </div>
    <div class="mt-16 col-span-3">
      <Button label="Kontynuj" size="large" class="w-full" @click="verify" />
    </div>
    <div class="border rounded border-red-500 bg-red-100 p-2 mt-2 mb-8 text-center text-lg text-red-500"
      v-if="apiResponseError">Niestety, weryfikacja się nie powiodła</div>
  </div>
</template>

<style scoped>
input::placeholder {
  font-size: 12px;
}
</style>
<script>
import { ref, defineComponent } from 'vue';
import InputText from 'primevue/inputtext';
import FloatLabel from 'primevue/floatlabel';
import Select from 'primevue/select';
import Button from 'primevue/button';
import axios from "axios";


export default defineComponent({
  name: 'GateKeeper',
  emits: ['odp'],
  props: [],
  components: {
    InputText,
    FloatLabel,
    Select,
    Button
  },
  setup(props, { emit }) {
    const firstName = ref(null);
    const lastName = ref(null);
    const dobDay = ref(null);
    const dobMonth = ref(null);
    const dobYear = ref(null);
    const months = ref([
      { name: 'Styczeń', code: '01' },
      { name: 'Luty', code: '02' },
      { name: 'Marzec', code: '03' },
      { name: 'Kwiecień', code: '04' },
      { name: 'Maj', code: '05' },
      { name: 'Czerwiec', code: '06' },
      { name: 'Lipiec', code: '07' },
      { name: 'Sierpień', code: '08' },
      { name: 'Wrzesień', code: '09' },
      { name: 'Październik', code: '10' },
      { name: 'Listopad', code: '11' },
      { name: 'Grudzień', code: '12' }
    ]);
    const apiResponseError = ref(false);
    const firstNameInvalid = ref(false);
    const lastNameInvalid = ref(false);
    const dayInvalid = ref(false);
    const monthInvalid = ref(false);
    const yearInvalid = ref(false);

    return {
      firstName,
      lastName,
      dobDay,
      dobMonth,
      dobYear,
      months,
      apiResponseError,
      firstNameInvalid,
      lastNameInvalid,
      dayInvalid,
      monthInvalid,
      yearInvalid,

      verify() {
        firstNameInvalid.value = (firstName.value === null || firstName.value.trim() === '') ? true : false;
        lastNameInvalid.value = (lastName.value === null || lastName.value.trim() === '') ? true : false;
        dayInvalid.value = (dobDay.value === null || dobDay.value.trim() === '') ? true : false;
        monthInvalid.value = (dobMonth.value === null) ? true : false;
        yearInvalid.value = (dobYear.value === null || dobYear.value.trim() === '') ? true : false;

        if (firstNameInvalid.value || lastNameInvalid.value || dayInvalid.value || monthInvalid.value || yearInvalid.value) {
          return false;
        }
        //handle case where people will use single digit for birth day
        let dob = (dobDay.value.length == 1) ? '0' + dobDay.value : dobDay.value;
        dob = dob + '/' + dobMonth.value.code + '/' + dobYear.value;
        axios({
          method: "POST",
          url: 'https://api.rodzina-jurasz.pl/index.php/api/auth',
          data: {
            firstName: firstName.value.trim(),
            lastName: lastName.value.trim(),
            dob: dob
          }
        }).then((response) => {
          apiResponseError.value = false;
          emit('odp', response.data);
        })
          .catch(() => {
            apiResponseError.value = true;
          })
      }
    }
  },
});
</script>
