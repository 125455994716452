<template>
  <SpeedDial :model="items" direction="down" :style="{ position: 'absolute', left: 50, top: 25 }"
    :buttonProps="{ severity: 'info', rounded: true }" :tooltipOptions="{ position: 'left' }" />
  <OrganizationChart v-model:selectionKeys="selection" :value="data" collapsible v-if="data && showTree">
    <template #person="slotProps">
      <div class="flex gap-4">
        <div class="flex flex-col items-center border p-4 min-w-36"
          :class="{ 'basis-1/2': slotProps.node.spouse, 'bg-blue-100': slotProps.node.sex == 'male', 'bg-pink-100': slotProps.node.sex == 'female' }">
          <Image alt="Image" preview v-if="slotProps.node.has_avatar_preview == '1'">
            <template #previewicon>
              <i class="pi pi-search"></i>
            </template>
            <template #image>
              <img :src="'/images/avatars/' + slotProps.node.id + '.jpg'" width="64" class="rounded-full"
                v-if="slotProps.node.has_avatar == '1'" />
              <i class="pi pi-user p-4 rounded-full" style="font-size: 2rem; background-color: #e2e8f0" v-else></i>
            </template>
            <template #preview>
              <img :src="'/images/other/' + slotProps.node.id + '.jpg'" alt="preview" />
            </template>
          </Image>
          <Avatar :image="'/images/avatars/' + slotProps.node.id + '.jpg'" class="mr-2" size="xlarge" shape="circle"
            v-if="slotProps.node.has_avatar == '1' && slotProps.node.has_avatar_preview == '0'" />
          <Avatar icon="pi pi-user" class="mr-2" size="xlarge" shape="circle"
            v-if="slotProps.node.has_avatar == '0' && slotProps.node.has_avatar_preview == '0'" />
          <span class="font-bold mb-2">{{ slotProps.node.first_name }} {{ slotProps.node.last_name }}<span
              class="text-sm font-normal"
              v-if="slotProps.node.sex == 'female' && slotProps.node.family_name !== slotProps.node.last_name"><br>z.d.
              {{ slotProps.node.family_name }}</span></span>
          <span v-if="slotProps.node.date_of_birth" class="flex text-xs"><img src="@/assets/baby-carriage.svg"
              class="w-4 h-4 mr-2" /> {{ slotProps.node.date_of_birth }}</span>
          <span v-if="slotProps.node.date_of_death" class="flex text-xs"><img src="@/assets/christianity.svg"
              class="w-4 h-4 mr-2" /> {{ slotProps.node.date_of_death }}</span>
        </div>
        <template v-if="typeof slotProps.node.spouse !== 'undefined' && slotProps.node.spouse !== null">
          <div class="flex flex-col items-center border p-4 basis-1/2 min-w-36"
            :class="{ 'bg-blue-100': slotProps.node.spouse.sex == 'male', 'bg-pink-100': slotProps.node.spouse.sex == 'female' }">
            <Image alt="Image" preview v-if="slotProps.node.spouse.has_avatar_preview == '1'">
              <template #previewicon>
                <i class="pi pi-search"></i>
              </template>
              <template #image>
                <img :src="'/images/avatars/' + slotProps.node.spouse.id + '.jpg'" width="64" class="rounded-full"
                v-if="slotProps.node.spouse.has_avatar == '1'" />
                <i class="pi pi-user p-4 rounded-full" style="font-size: 2rem; background-color: #e2e8f0" v-else></i>
              </template>
              <template #preview>
                <img :src="'/images/other/' + slotProps.node.spouse.id + '.jpg'" alt="preview" />
              </template>
            </Image>
            <Avatar :image="'/images/avatars/' + slotProps.node.spouse.id + '.jpg'" class="mr-2" size="xlarge"
              shape="circle"
              v-if="slotProps.node.spouse.has_avatar == '1' && slotProps.node.spouse.has_avatar_preview == '0'" />
            <Avatar icon="pi pi-user" class="mr-2" size="xlarge" shape="circle"
              v-if="slotProps.node.spouse.has_avatar == '0'" />
            <span class="font-bold mb-2">{{ slotProps.node.spouse.first_name }} {{ slotProps.node.spouse.last_name
              }}<span class="text-sm font-normal"
                v-if="slotProps.node.spouse.sex == 'female' && slotProps.node.spouse.family_name !== slotProps.node.last_name"><br>z.d.
                {{ slotProps.node.spouse.family_name }}</span></span>
            <span v-if="slotProps.node.spouse.date_of_birth" class="flex text-xs"><img src="@/assets/baby-carriage.svg"
                class="w-4 h-4 mr-2" /> {{ slotProps.node.spouse.date_of_birth }}</span>
            <span v-if="slotProps.node.spouse.date_of_death" class="flex text-xs mt-2"><img
                src="@/assets/christianity.svg" class="w-4 h-4 mr-2" /> {{ slotProps.node.spouse.date_of_death }}</span>
          </div>
        </template>
      </div>
    </template>
  </OrganizationChart>
  <div class="w-full flex justify-center px-4 pt-12" v-if="showGallery">
    <Galleria :value="images" :numVisible="5" :circular="true" containerStyle="max-width: 960px;background-color: #ccc"
      :showItemNavigators="true">
      <template #item="slotProps">
        <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt"
          style="width: auto; max-height: 800px; display: block" />
      </template>
      <template #thumbnail="slotProps">
        <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt"
          style="max-width: 80px;max-height:60px; display: block;" />
      </template>
    </Galleria>
  </div>
  <Dialog v-model:visible="showContact" modal :closeOnEscape="true" :closable="false" :style="{ width: '25rem' }">
    <template #header>
    </template>
    <span class="text-surface-500 dark:text-surface-400 block mb-8">Sugestie i prośby o zmiany (daty, zdjęcia, nazwiska, itp) można kierować na adres mailowy piotr.suplat@outlook.com</span>
    <template #footer>
      <Button label="Zamknij" text severity="secondary" @click="showContact = false" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue';
import axios from "axios";
import OrganizationChart from './organizationchart/OrganizationChart';
import Avatar from 'primevue/avatar';
import SpeedDial from 'primevue/speeddial';
import Image from 'primevue/image';
import Galleria from 'primevue/galleria';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { PhotoService } from '@/galleries/zjazd-13-07-2002/PhotoService';


export default defineComponent({
  name: 'FamilyTree',
  emits: ['temp', 'killSession'],
  props: ['uid', 'hash'],
  components: {
    OrganizationChart,
    Avatar,
    SpeedDial,
    Image,
    Galleria,
    Dialog,
    Button
  },
  setup(props, { emit }) {

    const data = ref(null);
    const showTree = ref(true);
    const showGallery = ref(false);
    const showContact = ref(false);

    const selection = ref({});

    const items = ref([
      {
        label: 'Drzewo genealogiczne',
        icon: 'pi pi-sitemap',
        command: () => {
          showGallery.value = false; showTree.value = true;
        }
      },
      {
        label: 'Zjazd 13/07/2002',
        icon: 'pi pi-images',
        command: () => {
          showTree.value = false; showGallery.value = true;
        }
      },
      {
        label: 'Kontakt',
        icon: 'pi pi-envelope',
        command: () => {
          showContact.value = true;
        }
      },
    ]);


    const images = ref();

    onMounted(() => {
      PhotoService.getImages().then((data) => (images.value = data));
    });

    const getFamily = () => {
      let uid = sessionStorage.getItem('rj-uid');
      let hash = sessionStorage.getItem('rj-hash');
      axios({
        method: "GET",
        url: 'https://api.rodzina-jurasz.pl/index.php/api/family?uid=' + uid + '&hash=' + hash,
        //headers: { Authorization: 'Bearer ' + store.getToken },
      }).then((response) => {
        data.value = response.data;
        emit('temp')
      })
        .catch((error) => {
          console.log(error.response)
          //console.log(error.response.data)
          if(error.response.status == 401 && error.response.data.messages.error == 'invalid session') {
            sessionStorage.removeItem("rj-uid");
            sessionStorage.removeItem("rj-hash");
          emit('killSession');
          }
        })
    };

    getFamily();

    return {
      selection,
      data,
      items,
      showTree,
      showGallery,
      showContact,
      images
    }
  },
});
</script>
