<template>
  <div class="flex w-screen h-screen justify-center items-center overflow" v-if="!validUser" >
    <GateKeeper @odp="setSession($event) "/>
  </div>
  <div class="w-auto h-screen p-4 pt-8" v-if="validUser">
    <FamilyTree @killSession="uid = null;hash = null; validUser = false"/>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
//import { usePrimeVue } from 'primevue/config';
import GateKeeper from './components/Gatekeeper.vue';
import FamilyTree from './components/FamilyTree.vue';


export default defineComponent({
  name: 'App',
  components: {
    GateKeeper,
    FamilyTree
},
  setup() {

    const validUser = ref(false);
    const uid = ref(null);
    const hash = ref(null);

    const setSession = (data) =>{
      sessionStorage.setItem("rj-uid", data.uid);
      sessionStorage.setItem("rj-hash", data.hash);
      validUser.value = true;
    }

    const checkSession = () => {
      validUser.value = (sessionStorage.getItem("rj-uid") === null || sessionStorage.getItem("rj-uid") === null) ? false : true;
    }

    checkSession();

    return {
      validUser,
      uid,
      hash,
      setSession
    }
  },
});
</script>
